/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import { RiPrinterLine, RiDownloadLine, RiArrowLeftLine, RiCheckLine, RiUserLine, RiTeamLine, RiBriefcaseLine, RiFileTextLine, RiTimeLine, RiBarChartBoxLine, RiStarLine, RiQuestionAnswerLine, RiLineChartLine, RiArrowRightSLine, RiCalendarCheckLine, RiFileUserLine } from "react-icons/ri"

const OnboardingSurveyTemplatePage = () => {
  
  // Function to handle printing the template
  const handlePrint = () => {
    if (typeof window !== 'undefined') {
      window.print();
    }
  }
  
  return (
    <Layout>
      <SEO 
        title="Onboarding Experience Survey | Optimize Your New Hire Process"
        description="Download our onboarding experience survey template to gather actionable feedback from new hires and continuously improve your onboarding process."
        keywords={[
          "onboarding experience survey",
          "new hire feedback form",
          "employee onboarding evaluation",
          "onboarding satisfaction survey",
          "onboarding process feedback",
          "employee orientation survey",
          "new employee experience questionnaire",
          "first 90 days feedback",
          "onboarding improvement metrics",
          "onboarding effectiveness survey"
        ]}
      />
      
      {/* Hero Section */}
      <div sx={{
        background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
        py: [4, 5],
        position: 'relative'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            maxWidth: '800px',
            mx: 'auto'
          }}>
            <Link 
              to="/guides/employee-onboarding" 
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                color: 'primary',
                fontSize: '0.9rem',
                textDecoration: 'none',
                mb: 3,
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              <RiArrowLeftLine sx={{ mr: 1 }} /> Back to Employee Onboarding Guide
            </Link>
            
            <h1 sx={{
              fontSize: ['1.8rem', '2.2rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary'
            }}>
              Onboarding Experience Survey Template
            </h1>
            
            <p sx={{
              fontSize: '1rem',
              lineHeight: 1.5,
              mb: 4,
              color: 'text',
              opacity: 0.9
            }}>
              A feedback form to evaluate and improve your onboarding process based on new hire experiences.
              Gather actionable insights to continuously enhance your employee onboarding program.
            </p>
            
            <div sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 3,
              mb: 4
            }}>
              <button 
                onClick={handlePrint}
                type="button"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'primary',
                  color: 'white',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  border: 'none',
                  cursor: 'pointer',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.15)'
                  }
                }}
              >
                <RiPrinterLine /> Print Survey
              </button>
              
              <Link 
                to="/contact"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'white',
                  color: 'primary',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  border: '2px solid',
                  borderColor: 'primary',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.05)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}
              >
                <RiDownloadLine /> Request Customizable Template
              </Link>
            </div>
          </div>
        </div>
      </div>
      
      {/* Main Content */}
      <div sx={{
        maxWidth: '1200px',
        mx: 'auto',
        px: 3,
        py: [4, 5],
        "@media print": {
          maxWidth: "100%",
          p: 0
        }
      }}>
        <div sx={{
          maxWidth: '800px',
          mx: 'auto'
        }}>
          {/* Introduction */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Why Measure Onboarding Experience?
            </h2>
            
            <p sx={{ mb: 4, lineHeight: 1.6 }}>
              Gathering feedback about your onboarding program directly from new employees provides invaluable insights 
              for continuous improvement. Research shows that 88% of organizations don't onboard well, yet effective
              onboarding can improve retention by 82% and productivity by over 70%. A well-designed onboarding survey 
              helps identify strengths and gaps in your process from the perspective that matters most—your new hires.
            </p>
            
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr 1fr'],
              gap: 4,
              mb: 4
            }}>
              {[
                {
                  title: "Identify Process Gaps",
                  description: "Discover areas where your onboarding process may be falling short or causing friction",
                  icon: <RiBarChartBoxLine />
                },
                {
                  title: "Measure Experience Quality",
                  description: "Quantify new hire satisfaction and evaluate the effectiveness of different onboarding elements",
                  icon: <RiStarLine />
                },
                {
                  title: "Gather Actionable Feedback",
                  description: "Collect specific suggestions for improving orientation, training, and integration activities",
                  icon: <RiQuestionAnswerLine />
                },
                {
                  title: "Track Improvement Over Time",
                  description: "Monitor onboarding performance and measure the impact of process changes and enhancements",
                  icon: <RiLineChartLine />
                }
              ].map((benefit, index) => (
                <div key={`benefit-${index}`} sx={{
                  p: 3,
                  borderRadius: '8px',
                  boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                  <div sx={{ 
                    display: 'flex', 
                    alignItems: 'flex-start',
                    mb: 2
                  }}>
                    <span sx={{ 
                      color: 'primary', 
                      fontSize: '1.5rem',
                      mr: 2,
                      lineHeight: 1
                    }}>
                      {benefit.icon}
                    </span>
                    <h3 sx={{ 
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      m: 0,
                      lineHeight: 1.3
                    }}>
                      {benefit.title}
                    </h3>
                  </div>
                  <p sx={{ 
                    fontSize: '0.95rem',
                    m: 0,
                    lineHeight: 1.5 
                  }}>
                    {benefit.description}
                  </p>
                </div>
              ))}
            </div>
          </section>
          
          {/* Survey Sections */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Key Survey Sections
            </h2>
            
            <p sx={{ mb: 4, lineHeight: 1.6 }}>
              A comprehensive onboarding experience survey should cover all critical aspects of the employee's 
              first weeks and months. These key sections will help you collect targeted feedback about specific 
              elements of your onboarding program.
            </p>
            
            <div sx={{
              mb: 4,
              borderRadius: '8px',
              overflow: 'hidden',
              boxShadow: '0 2px 15px rgba(0,0,0,0.06)'
            }}>
              {/* Pre-Onboarding Experience */}
              <div>
                <h3 sx={{
                  m: 0,
                  p: 3,
                  bg: 'primary',
                  color: 'white',
                  fontSize: '1.1rem',
                  fontWeight: 600
                }}>
                  <span sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    <RiCalendarCheckLine sx={{ mr: 2 }} /> Pre-Onboarding Experience
                  </span>
                </h3>
                
                <div sx={{ p: 3, bg: 'white' }}>
                  <p sx={{ fontSize: '0.95rem', mb: 3, lineHeight: 1.6 }}>
                    Evaluate the period between job acceptance and first day, assessing pre-arrival communications and preparation.
                  </p>
                  
                  <ul sx={{ 
                    listStyle: 'none',
                    p: 0,
                    m: 0
                  }}>
                    {[
                      "Communication clarity and timeliness before day one",
                      "Ease of completing pre-arrival paperwork and tasks",
                      "Information provided about first day expectations",
                      "Advanced access to necessary systems and resources",
                      "Overall impression of pre-boarding process"
                    ].map((item, index) => (
                      <li key={`preboarding-item-${index}`} sx={{ 
                        display: 'flex',
                        alignItems: 'flex-start',
                        py: 2,
                        borderBottom: index !== 4 ? '1px solid' : 'none',
                        borderColor: 'muted'
                      }}>
                        <span sx={{ color: 'primary', mr: 2, mt: '3px', flexShrink: 0 }}>
                          <RiCheckLine />
                        </span>
                        <span sx={{ fontSize: '0.95rem' }}>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              
              {/* First Day & Week */}
              <div sx={{ borderTop: '1px solid', borderColor: 'muted' }}>
                <h3 sx={{
                  m: 0,
                  p: 3,
                  bg: 'primary',
                  color: 'white',
                  fontSize: '1.1rem',
                  fontWeight: 600
                }}>
                  <span sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    <RiUserLine sx={{ mr: 2 }} /> First Day & Week Experience
                  </span>
                </h3>
                
                <div sx={{ p: 3, bg: 'white' }}>
                  <p sx={{ fontSize: '0.95rem', mb: 3, lineHeight: 1.6 }}>
                    Assess initial welcome experience, workspace readiness, introductions, and orientation activities.
                  </p>
                  
                  <ul sx={{ 
                    listStyle: 'none',
                    p: 0,
                    m: 0
                  }}>
                    {[
                      "First day welcome experience and quality of orientation",
                      "Workspace and technology readiness",
                      "Clarity of schedule and activities during first week",
                      "Team introductions and initial social integration",
                      "Access to necessary resources, equipment, and information"
                    ].map((item, index) => (
                      <li key={`firstweek-item-${index}`} sx={{ 
                        display: 'flex',
                        alignItems: 'flex-start',
                        py: 2,
                        borderBottom: index !== 4 ? '1px solid' : 'none',
                        borderColor: 'muted'
                      }}>
                        <span sx={{ color: 'primary', mr: 2, mt: '3px', flexShrink: 0 }}>
                          <RiCheckLine />
                        </span>
                        <span sx={{ fontSize: '0.95rem' }}>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              
              {/* Training & Role Clarity */}
              <div sx={{ borderTop: '1px solid', borderColor: 'muted' }}>
                <h3 sx={{
                  m: 0,
                  p: 3,
                  bg: 'primary',
                  color: 'white',
                  fontSize: '1.1rem',
                  fontWeight: 600
                }}>
                  <span sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    <RiBriefcaseLine sx={{ mr: 2 }} /> Training & Role Clarity
                  </span>
                </h3>
                
                <div sx={{ p: 3, bg: 'white' }}>
                  <p sx={{ fontSize: '0.95rem', mb: 3, lineHeight: 1.6 }}>
                    Evaluate effectiveness of job training, role expectations, and performance standards.
                  </p>
                  
                  <ul sx={{ 
                    listStyle: 'none',
                    p: 0,
                    m: 0
                  }}>
                    {[
                      "Quality and comprehensiveness of role-specific training",
                      "Clarity of job responsibilities and expectations",
                      "Effectiveness of training methods and materials",
                      "Availability of ongoing learning resources",
                      "Comfort level with job tasks after training period"
                    ].map((item, index) => (
                      <li key={`training-item-${index}`} sx={{ 
                        display: 'flex',
                        alignItems: 'flex-start',
                        py: 2,
                        borderBottom: index !== 4 ? '1px solid' : 'none',
                        borderColor: 'muted'
                      }}>
                        <span sx={{ color: 'primary', mr: 2, mt: '3px', flexShrink: 0 }}>
                          <RiCheckLine />
                        </span>
                        <span sx={{ fontSize: '0.95rem' }}>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              
              {/* Manager & Team Support */}
              <div sx={{ borderTop: '1px solid', borderColor: 'muted' }}>
                <h3 sx={{
                  m: 0,
                  p: 3,
                  bg: 'primary',
                  color: 'white',
                  fontSize: '1.1rem',
                  fontWeight: 600
                }}>
                  <span sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    <RiTeamLine sx={{ mr: 2 }} /> Manager & Team Support
                  </span>
                </h3>
                
                <div sx={{ p: 3, bg: 'white' }}>
                  <p sx={{ fontSize: '0.95rem', mb: 3, lineHeight: 1.6 }}>
                    Assess manager involvement, team integration, and support systems available to new hires.
                  </p>
                  
                  <ul sx={{ 
                    listStyle: 'none',
                    p: 0,
                    m: 0
                  }}>
                    {[
                      "Manager accessibility and support during onboarding",
                      "Quality and frequency of feedback and check-ins",
                      "Team members' helpfulness and inclusivity",
                      "Buddy/mentor program effectiveness (if applicable)",
                      "Sense of belonging and team integration"
                    ].map((item, index) => (
                      <li key={`support-item-${index}`} sx={{ 
                        display: 'flex',
                        alignItems: 'flex-start',
                        py: 2,
                        borderBottom: index !== 4 ? '1px solid' : 'none',
                        borderColor: 'muted'
                      }}>
                        <span sx={{ color: 'primary', mr: 2, mt: '3px', flexShrink: 0 }}>
                          <RiCheckLine />
                        </span>
                        <span sx={{ fontSize: '0.95rem' }}>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              
              {/* Culture & Company Overview */}
              <div sx={{ borderTop: '1px solid', borderColor: 'muted' }}>
                <h3 sx={{
                  m: 0,
                  p: 3,
                  bg: 'primary',
                  color: 'white',
                  fontSize: '1.1rem',
                  fontWeight: 600
                }}>
                  <span sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    <RiFileUserLine sx={{ mr: 2 }} /> Culture & Company Overview
                  </span>
                </h3>
                
                <div sx={{ p: 3, bg: 'white' }}>
                  <p sx={{ fontSize: '0.95rem', mb: 3, lineHeight: 1.6 }}>
                    Evaluate how well company values, mission, and culture were communicated and experienced.
                  </p>
                  
                  <ul sx={{ 
                    listStyle: 'none',
                    p: 0,
                    m: 0
                  }}>
                    {[
                      "Clarity of company mission, vision, and values communication",
                      "Understanding of organizational structure and key departments",
                      "Alignment between described and experienced company culture",
                      "Effectiveness of company history and product/service overview",
                      "Feeling connected to the company's purpose and goals"
                    ].map((item, index) => (
                      <li key={`culture-item-${index}`} sx={{ 
                        display: 'flex',
                        alignItems: 'flex-start',
                        py: 2,
                        borderBottom: index !== 4 ? '1px solid' : 'none',
                        borderColor: 'muted'
                      }}>
                        <span sx={{ color: 'primary', mr: 2, mt: '3px', flexShrink: 0 }}>
                          <RiCheckLine />
                        </span>
                        <span sx={{ fontSize: '0.95rem' }}>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              
              {/* Overall Experience */}
              <div sx={{ borderTop: '1px solid', borderColor: 'muted' }}>
                <h3 sx={{
                  m: 0,
                  p: 3,
                  bg: 'primary',
                  color: 'white',
                  fontSize: '1.1rem',
                  fontWeight: 600
                }}>
                  <span sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    <RiStarLine sx={{ mr: 2 }} /> Overall Experience & Recommendations
                  </span>
                </h3>
                
                <div sx={{ p: 3, bg: 'white' }}>
                  <p sx={{ fontSize: '0.95rem', mb: 3, lineHeight: 1.6 }}>
                    Capture overall satisfaction, highlight what worked well, and identify improvement opportunities.
                  </p>
                  
                  <ul sx={{ 
                    listStyle: 'none',
                    p: 0,
                    m: 0
                  }}>
                    {[
                      "Overall satisfaction with the onboarding experience",
                      "Most valuable aspects of the onboarding process",
                      "Areas that could be improved or enhanced",
                      "Additional resources or support that would have been helpful",
                      "Open-ended suggestions for improving the onboarding experience"
                    ].map((item, index) => (
                      <li key={`overall-item-${index}`} sx={{ 
                        display: 'flex',
                        alignItems: 'flex-start',
                        py: 2,
                        borderBottom: index !== 4 ? '1px solid' : 'none',
                        borderColor: 'muted'
                      }}>
                        <span sx={{ color: 'primary', mr: 2, mt: '3px', flexShrink: 0 }}>
                          <RiCheckLine />
                        </span>
                        <span sx={{ fontSize: '0.95rem' }}>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </section>
          
          {/* Download Button */}
          <div sx={{
            display: 'flex',
            justifyContent: 'center',
            my: 5
          }}>
            <Link 
              to="/contact"
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'primary',
                color: 'white',
                py: 2,
                px: 4,
                borderRadius: '6px',
                fontSize: '1rem',
                fontWeight: 600,
                textDecoration: 'none',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 6px 15px rgba(0,0,0,0.15)'
                }
              }}
            >
              <RiDownloadLine /> Download Full Survey Template
            </Link>
          </div>
          
          {/* Implementation Best Practices */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Implementation Best Practices
            </h2>
            
            <div sx={{
              bg: '#f8f9fa',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 15px rgba(0,0,0,0.06)'
            }}>
              <ul sx={{
                listStyle: 'none',
                p: 0,
                m: 0
              }}>
                {[
                  "Send the survey at multiple touchpoints (30, 60, and 90 days) to capture evolving perspectives",
                  "Keep the survey anonymous to encourage honest, candid feedback",
                  "Use a mix of rating scales and open-ended questions for quantitative and qualitative insights",
                  "Include department-specific questions to evaluate different onboarding components",
                  "Share aggregated results with relevant stakeholders, including HR, managers, and executives",
                  "Close the feedback loop by communicating improvements made based on survey results",
                  "Compare data over time to identify trends and measure onboarding program improvements",
                  "Use digital survey tools that make completion easy on any device"
                ].map((tip, index) => (
                  <li key={`implementation-tip-${index}`} sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    mb: 3,
                    fontSize: '0.95rem',
                    lineHeight: 1.5
                  }}>
                    <span sx={{
                      color: 'primary',
                      mr: 3,
                      mt: '3px',
                      flexShrink: 0
                    }}>
                      <RiCheckLine />
                    </span>
                    {tip}
                  </li>
                ))}
              </ul>
            </div>
          </section>
          
          {/* Related Resources */}
          <section>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Related Resources
            </h2>
            
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', null, 'repeat(2, 1fr)'],
              gap: 4
            }}>
              {[
                {
                  title: "New Employee Welcome Kit",
                  description: "Comprehensive package of orientation materials, company information, and first-day essentials.",
                  link: "/resources/templates/welcome-kit-template"
                },
                {
                  title: "Onboarding Process Checklist",
                  description: "Structured timeline of tasks for HR, managers, and new hires during the onboarding process.",
                  link: "/resources/templates/onboarding-checklist-template"
                },
                {
                  title: "Role-Specific Training Plan",
                  description: "Customizable template for creating structured learning paths for different roles.",
                  link: "/resources/templates/training-plan-template"
                },
                {
                  title: "Employee Onboarding Guide",
                  description: "Comprehensive guide to effective employee onboarding best practices and strategies.",
                  link: "/guides/employee-onboarding"
                }
              ].map((resource, index) => (
                <Link 
                  key={`related-resource-${index}`}
                  to={resource.link}
                  sx={{
                    textDecoration: 'none',
                    color: 'text',
                    p: 3,
                    borderRadius: '8px',
                    boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                    display: 'flex',
                    flexDirection: 'column',
                    bg: 'white',
                    '&:hover': {
                      transform: 'translateY(-3px)',
                      boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                    }
                  }}
                >
                  <h3 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2,
                    color: 'primary'
                  }}>
                    {resource.title}
                  </h3>
                  
                  <p sx={{
                    fontSize: '0.95rem',
                    lineHeight: 1.5,
                    mb: 3,
                    flexGrow: 1
                  }}>
                    {resource.description}
                  </p>
                  
                  <div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '0.9rem',
                    fontWeight: 600,
                    color: 'primary',
                    mt: 'auto'
                  }}>
                    View Resource <RiArrowRightSLine sx={{ ml: 1 }} />
                  </div>
                </Link>
              ))}
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default OnboardingSurveyTemplatePage

 